
export const formData = [
    {
        id: 1,
        text: 'Программ хангамж',
    },
    {
        id: 2,
        text: 'Компьютерын ухаан',
    },
    {
        id: 3,
        text: 'Мэдээллийн технологи',
    },
    {
        id: 4,
        text: 'Мэдээллийн систем',
    },
    {
        id: 5,
        text: 'Өгөгдлийн ухаан',
    },
    {
        id: 6,
        text: 'Хиймэл оюун ухаан',
    },
    {
        id: 7,
        text: 'Электроникийн инженер',
    },
    {
        id: 8,
        text: 'Кибер аюулгүй байдал',
    },
    {
        id: 9,
        text: 'IOT технологи',
    },
    {
        id: 10,
        text: 'Утасгүй холбоо',
    },
    {
        id: 11,
        text: 'Робот ба хиймэл оюун ухаан',
    }
  ];
